/******* APP *******/

.site-layout-content {
  min-height: 300px;
  padding: 10px;
  background: #fff;
}

.logo img {
  width: 225px;
  background-color: white;;
  padding: 2px 10px 2px 2px;
}

h2.ant-typography.headerTitle, .ant-typography h2.headerTitle {
  margin: 0;
  padding: 0;
  line-height: auto;
  color: white;
  padding-left: 15px;
}

.loginContainer {
  color: white;
  float: right;
  
}

.tip,
.intro {
  position: relative;
  padding: 10px 12px;
  overflow: hidden;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.grayFormBody {
  margin: 0;
  padding: 25px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

/* --------------------- */

.ant-table span.anticon.grid-icon {
  font-size: 16px;
}

.ant-table-content {
  border: solid 1px rgba(0, 0, 0, 0.12);
  /* border-bottom: 0 none; */
}

h3.ant-typography,
h4.ant-typography {
  margin-bottom: 25px;
}

div.ant-drawer-header {
  background-color: #106ebe !important;
}

button.ant-drawer-close span.anticon-close {
  color: white !important;
}

div.ant-drawer-title {
  font-weight: 600;
  font-size: 20px;
  color: white;
}

div.ant-descriptions-title {
  font-size: 20px;
  font-weight: 600;
}

.ant-btn-link {
  height: auto !important;
  margin: auto;
  padding: 0;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-label > label {
  font-size: 12.5px;
  font-weight: 500;
}

.ant-card-head {
  background-color: #ebedf0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 175px;
}

.ant-card {
  border: solid 1px #ddd;
}

div.ant-card-body ul {
  margin: 0;
  padding: 0 20px;
}

div.ant-steps-item:last-child {
  padding-right: 20px;
}

.ant-empty-normal {
  margin: 10px 0;
}

th.ant-table-cell {
  /* ant table header, no wrap */
  white-space: nowrap;
}

.ant-table-cell,
.ant-table-cell button {
  font-size: 12.5px;
}

.ant-table-cell span.ant-tag
{
  /* line-height: 18px; */
  /* font-size: 11px; */
  /* padding: 1px 4px; */
  white-space: normal;
}

.ant-descr-comments {
  font-style: italic;
  font-weight: 500;
}

.ant-icon-custfont .anticon {
  font-size: 17px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 16px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 16px !important;
}

.ant-table-wrapper, .ant-table-content {
  overflow: auto;
}

.ant-table-cell .ant-btn {
  text-align: left;
  white-space: normal;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.60);
}

.div-quote-summary th.ant-descriptions-item-label{
  font-weight: 500;
  font-size: 12.5px;
}
.div-quote-summary td.ant-descriptions-item-content {
  padding-right: 50px !important;
  font-size: 12.5px;
}
.div-quote-summary .ant-descriptions-item-label{
  width: 195px;
}

.datadump {
  margin: 0;
  padding: 4px;
  border: solid 1px #999;
  color: #666;
  font-size: 11px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.datadump span {
  display: block;
  font-weight: bold;
}

.quote_grid_totals {
  font-weight: bold !important;
  display: inline-block;
  width: 100%;
  text-align: right;
}
