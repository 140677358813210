@import '~antd/dist/antd.css';

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/******* STANDARD *******/
.termCompareTable {
  border-collapse: collapse;
  width: 100%;
}

.termCompareTable th {
  background-color: gainsboro;
}

.termCompareTable .numberCell {
  text-align: right;
}

.termCompareTable td,
.termCompareTable th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.termCompareTable tr:nth-child(even) {
  background-color: #fafafa;

}

.hideme {
  display: none;
}

.showme {
  display: block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-inline {
  display: inline-block;
  min-width: 250px;
}

.clear {
  clear: both;
  font-size: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1px;
}

.clr {
  margin: 0;
  padding: 0;
  border: 0;
}

.flex {
  display: flex;
}

.wbs {
  margin: 0 0 12px 0 !important;
}

.wbs0 {
  margin: 0 !important;
}

.wbs4 {
  margin: 0 0 4px 0 !important;
}

.wbs8 {
  margin: 0 0 8px 0 !important;
}

.wbs10 {
  margin: 0 0 10px 0 !important;
}

.wbs12 {
  margin: 0 0 12px 0 !important;
}

.wbs25 {
  margin: 0 0 25px 0 !important;
}

.wts25 {
  margin: 25px 0 0 0 !important;
}

.wtbs25 {
  margin: 25px 0 25px 0 !important;
}

.nbm {
  margin-bottom: 0 !important;
}

.wrapper8 {
  margin: 8px !important;
  padding: 0;
  border: 0;
}

.wrapper10 {
  margin: 10px !important;
  padding: 0;
  border: 0;
}

.wrapper12 {
  margin: 12px !important;
  padding: 0;
  border: 0;
}

.wrapper25 {
  margin: 25px !important;
  padding: 0;
  border: 0;
}

.padding-right {
  padding-right: 8px;
}

.padding-left {
  padding-left: 8px;
}

.margin-right {
  margin-right: 8px;
}

.margin-left {
  margin-left: 8px;
}

.no-wrap,
.nowrap {
  white-space: nowrap;
}

.required {
  font-style: italic !important;
  color: red !important;
  font-size: 12px !important;
}

.invalid-entry {
  font-style: italic !important;
  color: red !important;
  font-size: 13px !important;
  white-space: nowrap;
}

.color-red {
  color: red !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/******* BAR: b&r solutions *******/

.br-bold,
.bar-bold {
  font-weight: bold !important;
}

.br-em,
.bar-em {
  font-style: italic !important;
}

.br-hover-ul:hover,
.bar-hover-ul:hover {
  text-decoration: underline !important;
}

.br-pointer,
.bar-pointer {
  cursor: pointer !important;
}

/******* WIDTHS *******/

.w50p {
  width: 50%;
}

.w75p {
  width: 75%;
}

.w80p {
  width: 80%;
}

.w98p {
  width: 98%;
}

.w100p {
  width: 100%;
}

.w25 {
  width: 25px;
}

.w50 {
  width: 50px;
}

.w75 {
  width: 75px;
}

.w100 {
  width: 100px;
}

.w125 {
  width: 125px;
}

.w150 {
  width: 150px;
}

.w175 {
  width: 175px;
}

.w200 {
  width: 200px;
}

.w225 {
  width: 225px;
}

.w250 {
  width: 250px;
}

.w275 {
  width: 275px;
}

.w300 {
  width: 300px;
}

.w325 {
  width: 325px;
}

.w350 {
  width: 350px;
}

.w375 {
  width: 375px;
}

.w400 {
  width: 400px;
}

.w425 {
  width: 425px;
}

.w450 {
  width: 450px;
}

.w475 {
  width: 475px;
}

.w500 {
  width: 500px;
}

.w525 {
  width: 525px;
}

.w550 {
  width: 550px;
}

.w575 {
  width: 575px;
}

.w600 {
  width: 600px;
}

.w625 {
  width: 625px;
}

.w650 {
  width: 650px;
}

.w675 {
  width: 675px;
}

.w700 {
  width: 700px;
}

.w725 {
  width: 725px;
}

.w750 {
  width: 750px;
}

.w775 {
  width: 775px;
}

.w800 {
  width: 800px;
}

.w825 {
  width: 825px;
}

.w850 {
  width: 850px;
}

.w875 {
  width: 875px;
}

.w900 {
  width: 900px;
}

.w925 {
  width: 925px;
}

.w950 {
  width: 950px;
}

.w975 {
  width: 975px;
}

.w1000 {
  width: 1000px;
}